import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../components/SEO';
import Header from '../../components/Header';
import ContentRow from '../../components/ContentRow';
import ImgW from '../../components/ImgW';
import { commonHeaderColor } from '../../util';


const MyNeoSurfPage = ({ data }) => (
  <>
    <SEO
      title="MyNeoSurf E-wallet - Online Casino &amp; Gambling Payments"
      description="Sign up with MyNeoSurf - the perfect solution for all your gaming needs. Deposit &amp; Cash-out safely via MyNeoSurf e-wallet."
      alternateLanguagePages={[
        {
          path: 'myneosurf',
          language: 'en'
        },
        {
          path: 'myneosurf',
          language: 'fr'
        },
        {
          path: 'myneosurf',
          language: 'de'
        }
      ]}
    />
    <Header backgroundColor={commonHeaderColor} />
    <ContentRow backgroundColor={commonHeaderColor} padV>
      <div className="grid c-2">
        <div>
          <a href="https://bit.ly/2AlU6Ch">
            <Img fluid={data.myneosurf.childImageSharp.fluid} alt="MyNeoSurf Logo" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="smaller h3 smaller">MyNeoSurf - Die perfekte E-Wallet-Lösung</h1>
            <p className="textColorDark">Wenn du bereits Neosurf nutzt, weißt du, dass es sich um ein voucherbasiertes Zahlungssystem handelt. Du weißt vielleicht nicht, dass es auch eine Online-Komponente zu NeoSurf gibt: MyNeoSurf. Ähnlich wie die Gutscheine ist es sicher, geschützt und einfach. Es ist eine Methode für Online-Zahlungen, die schnell und bequem ist.</p>
            <a href="https://www.myneosurf.com/en_GB" className="button">Besorge MyNeosurf</a>
          </div>
        </div>
      </div>
    </ContentRow>
    <ContentRow padV>
      <div className="grid c-2">
        <div>
          <div className="contentBox">
            <h2 className="smaller textUppercase">Was ist MyNeoSurf?</h2>
            <p className="textColorDark">MyNeoSurf ist im Grunde eine E-Wallet. Du kannst Geld darauf laden, indem du Neosurf-Gutscheine verwendest, die du an Verkaufsstellen gekauft hast. Sobald dein Online-Konto aufgeladen ist, kannst du Geld an Freunde senden und Online-Zahlungen tätigen. Die E-Wallet wird derzeit von über 350.000 Personen genutzt und kann auf mehr als 20.000 Websites verwendet werden, von Amazon und League of Legends bis hin zu Online-Casino-Websites wie AU Slots, SpaceLilly und Lucky Star Casino. Du kannst sogar Geld von Geldautomaten abheben, wenn du eine Neocash MasterCard hast.</p>
            <h2 className="smaller textUppercase">Warum MyNeoSurf?</h2>
            <ul>
              <li className="textColorDark">Wurde deine bevorzugte E-Wallet (Neteller, Skrill) kürzlich in deinem Land gesperrt?</li>
              <li className="textColorDark">Bevorzugst du sichere, geschützte und einfache Einzahlungen auf Online-Glücksspielseiten?</li>
              <li className="textColorDark">Liebst du die Möglichkeit, Casino-Chips auf eine E-Wallet auszuzahlen und Geld über einen Geldautomaten abzuheben?</li>
            </ul>
            <p className="textColorDark">MyNeoSurf könnte die perfekte Lösung für dich sein! Alle Funktionen der E-Wallet stehen dir zur Verfügung: von sicheren Einzahlungen bis zu blitzschnellen Auszahlungen!</p>
          </div>
        </div>
        <div>
          <Img fluid={data.myneosurfCard.childImageSharp.fluid} alt="MyNeoSurf e-wallet &amp; Online Casinos" />
        </div>
      </div>
    </ContentRow>
    <ContentRow backgroundColor="#eee" padV>
      <h2 className="smaller textUppercase">MyNeoSurf E-Wallet & Online-Casinos</h2>
      <p className="textColorDark">Um MyNeoSurf zu nutzen, musst du dich registrieren und ein Online-Konto erstellen. Glücklicherweise ist dies sehr einfach und dauert nicht lange. Sobald dein Konto eingerichtet ist, kannst du es mit Codes von Neosurf-Gutscheinen aufladen. Außerdem kannst du dein Konto mit Kreditkarten oder Banküberweisungen aufladen.</p>
      <ImgW center fluid={data.fundAccount.childImageSharp.fluid} alt="Lade dein Konto mit Gutscheinen, Karten oder Banküberweisung auf" />
      <p>Mit deinem Konto kannst du deine Gutscheine einfach verwalten und bei Transaktionen, die Währungsunterschiede betreffen, gute Wechselkurse erhalten. Um Geld von deinem MyNeoSurf-Konto abzuheben, kannst du eine Banküberweisung (eine Gebühr von 1,5 % fällt an) oder deine Neocash MasterCard (eine Gebühr von 2 % fällt an) nutzen. Normalerweise gibst du bei der Verwendung von Neosurf-Gutscheinen einfach den Code ein und lässt die Mittel deinem Konto hinzufügen. Mit einem MyNeoSurf-Konto kannst du alle deine Codes an einem Ort aufbewahren und verwalten.</p>
    </ContentRow>
    <ContentRow padV>
      <div className="grid c-2">
        <div>
          <Img fluid={data.neoCash.childImageSharp.fluid} alt="NeoCash Mastercard" />
        </div>
        <div>
          <div className="contentBox">
            <h3 className="smaller textUppercase">Neocash MasterCard</h3>
            <p>Dies ist eine Debitkarte, die mit deinem MyNeoSurf-Online-Konto verbunden ist. Du kannst einen Antrag stellen, sobald dein Konto eingerichtet ist. Wie der Name schon sagt, handelt es sich um eine Art MasterCard, die überall dort verwendet werden kann, wo MasterCard akzeptiert wird. Du kannst sie sowohl online als auch offline nutzen.</p>
            <p>Wie bereits erwähnt, kannst du sie verwenden, um Geldautomatenabhebungen von deinem MyNeoSurf-Konto vorzunehmen. Dazu musst du einfach deine PIN am Geldautomaten eingeben und auswählen, wie viel du abheben möchtest.</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <div className="pageWidth">
      <h2 className="smaller textColorDark">Unterschied zwischen Neosurf und MyNeoSurf</h2>
      <p>MyNeoSurf ist die "nächste Stufe" der Lösung des Unternehmens, das NeoSurf betreibt. Der Unterschied ist erheblich. NeoSurf ist ein Prepaid-Gutschein, der naturgemäß eine etwas eingeschränkte Transaktionslösung darstellt. Eingeschränkt durch eine Reihe von Faktoren, wie die Tatsache, dass du sie physisch kaufen musst (die Gutscheine) – was bedeutet, dass du planen und die Gutscheine im Voraus kaufen musst. Eingeschränkt durch die Tatsache, dass du mit Neosurf-Gutscheinen nur Geld senden, aber nicht empfangen kannst.</p>
      <ImgW center fluid={data.myNeoVsNeo.childImageSharp.fluid} alt="MyNeosurf VS Neosurf" />
      <p>MyNeoSurf hingegen hat keine dieser Einschränkungen. MyNeoSurf ist eine E-Wallet. E-Wallets sind Online-/digitale/elektronische Geldbörsen. Du musst deine Einkäufe nicht im Voraus planen, kannst sowohl Geld senden als auch empfangen und kannst sogar die MyNeoSurf-Debitkarte bestellen und deine Mittel in Geschäften physisch ausgeben oder sogar an Geldautomaten abheben.</p>
      <h2 className="smaller textColorDark">E-wallets 101</h2>
      <p>E-Wallets oder elektronische Geldbörsen gibt es schon seit einiger Zeit. Sie erblickten erstmals Ende der 1990er Jahre das Licht der Welt, obwohl sie zu diesem Zeitpunkt nicht wirklich von Verbrauchern weit verbreitet angenommen wurden. Fortschritte in unserer Technologie, Internetgeschwindigkeit und Zugänglichkeit sowie die digitale Kompetenz der Menschen haben das geändert. E-Wallets werden jetzt weltweit häufig genutzt, was nicht überraschend ist, da sie zahlreiche Vorteile bieten.</p>
      <ul>
        <li className="textColorDark">Sie sind schnell und sicher</li>
        <li className="textColorDark">Sie werden weit akzeptiert</li>
        <li className="textColorDark">Du kannst Mittel empfangen, senden und ausgeben und sogar deine Ausgabengewohnheiten effizient verfolgen</li>
        <li className="textColorDark">Du kannst eine Debitkarte bestellen, die mit deiner E-Wallet verbunden ist und sie wie deine Bankkarte verwenden</li>
        <li className="textColorDark">Du kannst deine Mittel problemlos zwischen deiner E-Wallet und deinem Bankkonto transferieren</li>
      </ul>
      <h3 className="smaller textColorDark">Wie funktionieren diese E-Wallets genau?</h3>
      <p>Es ist eigentlich ganz einfach.</p>
      <p>Du gehst zur Website der E-Wallet und erstellst ein Konto, genau wie bei jedem anderen Konto (zum Beispiel einem E-Mail-Konto). Das ist alles. Du bist jetzt Besitzer eines E-Wallet-Kontos. Du kannst dich in dein Konto einloggen und es nutzen. Das erste, was du tun möchtest, ist, dein Konto mit Geld aufzuladen. Du kannst einfach deine Bankkarte mit deiner E-Wallet verbinden – und einige Mittel von deinem Bankkonto auf dein E-Wallet-Konto senden. Jetzt hast du eine aktive E-Wallet mit Geld darin! Du kannst dieses Geld in deiner E-Wallet verwenden, um Produkte und Dienstleistungen in Millionen von Online-Shops, Spielen und einer Vielzahl anderer Dienste zu kaufen. Du kannst die Mittel blitzschnell, sicher und günstig an deine Freunde und Familie senden und auch von ihnen empfangen.</p>
      <ImgW center fluid={data.eWallets.childImageSharp.fluid} alt="E-Wallets – Weit verbreitet online" />
      <p>Darüber hinaus kannst du eine physische E-Wallet-Debitkarte bestellen und sie verwenden, um Waren und Produkte in Geschäften auf der ganzen Welt zu kaufen oder sogar Bargeld an Geldautomaten in allen Ecken der Welt abzuheben. Heutzutage haben die meisten E-Wallets sogar mobile Apps, die du herunterladen und auf deinem Smartphone installieren kannst, um deine Mittel jederzeit und überall zu empfangen, zu senden und auszugeben, auch „unterwegs“, sowie deine Ausgaben zu verfolgen, zu überwachen und sogar zu begrenzen, was eine große Hilfe für dein monatliches Budget sein kann.</p>
      <p>Insgesamt sind die VORTEILE der Verwendung einer E-Wallet in deinem Alltag zahlreich, während die NACHTEILE nicht existieren.</p>
      <p>MyNeoSurf E-Wallet ist eine der neueren E-Wallet-Lösungen, die den Markt im Sturm erobert.</p>
      <ul>
        <li className="textColorDark">Sie wird weit akzeptiert.</li>
        <li className="textColorDark">Sie ist einfach, schnell und sicher.</li>
        <li className="textColorDark">Sie bietet eine Vielzahl von Sprachen für deine Bequemlichkeit.</li>
      </ul>
      <h2 className="smaller textColorDark">MyNeoSurf im Online-Gaming</h2>
      <p>Wie bereits erwähnt, kann die MyNeoSurf E-Wallet verwendet werden, um Produkte und Dienstleistungen in einer Reihe von Online-Shops, Buchungs- und Gaming-Websites zu kaufen; von einem der meistgenutzten Online-Shops – Amazon, bis hin zu einem der meistgespielten Spiele – League of Legends, aber sie kann auch in vielen Casinos verwendet werden!</p>
      <p>Was sind die Vorteile von MyNeoSurf im Online-Glücksspiel im Vergleich zu Gutscheinen und Kreditkarten?</p>
      <h3 className="smaller textColorDark">MyNeoSurf E-Wallet vs. Gutscheine</h3>
      <p>Gutscheine, wie bereits erwähnt, müssen im Voraus an bestimmten Standorten physisch gekauft werden, während du MyNeoSurf jederzeit und überall aufladen kannst.</p>
      <p>Du kannst Gutscheine nur verwenden, um auf dein Spielerkonto einzuzahlen, während du mit der MyNeoSurf E-Wallet sowohl einzahlen als auch dein Geld abheben kannst.</p>
      <h3 className="smaller textColorDark">MyNeoSurf E-Wallet vs. Kreditkarten</h3>
      <p>Du kannst beide für Einzahlungen und Auszahlungen verwenden, aber du musst bei Kreditkarten auf deine Auszahlungen Tage warten, während du sie nahezu sofort auf dein MyNeoSurf E-Wallet erhältst.</p>
      <p>Abhängig von den Zahlungsabwicklern und deiner Nationalität können deine Kreditkarten in einigen Casinos akzeptiert und in anderen abgelehnt werden. Es kann auch das Problem auftreten, dass deine Einzahlungen akzeptiert werden, aber Auszahlungen auf dieselbe Kreditkarte nicht, wiederum abhängig von den Zahlungsabwicklern, Lizenzen, deiner Bank usw. Dieses Problem wirst du mit der MyNeoSurf E-Wallet niemals haben. MyNeoSurf E-Wallet wird bereits in vielen Casinos akzeptiert, mit einer Tendenz, in Zukunft noch mehr akzeptiert zu werden. Wenn es als Zahlungsmethode vom Casino akzeptiert wird, wirst du niemals Probleme mit Auszahlungen auf dein MyNeoSurf E-Wallet haben.</p>
      <ImgW center fluid={data.wideRange.childImageSharp.fluid} alt="Eine große Auswahl an Online-Casinos, die MyNeoSurf unterstützen" />
      <p>Zusammenfassend lässt sich sagen, dass ein weiterer erstaunlicher Vorteil der MyNeoSurf E-Wallet im Online-Glücksspiel die Kontrolle über deine Ausgaben und das Budget ist. Das Erstaunliche ist, dass du die Höhe der Mittel, die du für dein Glücksspiel einplanen möchtest, im Auge behalten kannst. Da es von deinem Bankkonto getrennt ist, kannst du im Voraus festlegen, wie viel Geld du wöchentlich oder monatlich spielen möchtest, und MyNeoSurf ist eine E-Wallet-Lösung, die es Nutzern ermöglicht, schnell und sicher Gelder über NeoSurf-Gutscheine hochzuladen und Online-Zahlungen vorzunehmen. Es bietet die Flexibilität, Geld zu senden und zu empfangen, Fonds zu verwalten und Bargeld mit der Neocash MasterCard abzuheben. Im Gegensatz zu NeoSurf-Gutscheinen, die nur für Einzahlungen verwendet werden können, unterstützt MyNeoSurf sowohl Einzahlungen als auch Abhebungen und ist auf verschiedenen Online-Plattformen, einschließlich Casinos, weit verbreitet. Es hilft Nutzern, ihre Glücksspielbudgets zu kontrollieren, indem es die Spielmittel von ihren Bankkonten trennt.</p>
      <p className="textAlignCenter"><strong>Suchen Sie Online-Casinos, die MyNeoSurf-Einzahlungen akzeptieren?</strong></p>
      <div className="grid c-4 bigGap">
        <div>
          <div className="contentBox">
            <a href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648">
              <Img fluid={data.luckystar.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="https://www.betbtc.io/sign-up/a48acf12">
              <Img fluid={data.betbtc.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648">
              <Img fluid={data.emu.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="https://www.777slotsbay.com/?goft=355fce1f-1701-4bc8-b29d-e3e0ec292198">
              <Img fluid={data.sevenSB.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="http://www.777bay.com/?a_aid=5a6fb263dabe6">
              <Img fluid={data.sevenB.childImageSharp.fluid} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default MyNeoSurfPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    myneosurf: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/myneosurf-header.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    myneosurfCard: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/myneosurf-card.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 600,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fundAccount: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/Fund-your-account-with-vouchers-cards-or-banks.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 524,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    neoCash: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/neocashmaster.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 505,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    myNeoVsNeo: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/MyNeosurf-VS-Neosurf.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 800,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    eWallets: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/E-wallets-Widely-accepted-online.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 800,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    wideRange: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/Wide-range-of-online-casinos-are-MyNeoSurf-friendly.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 800,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    luckystar: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/lucky-logo.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 328,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    betbtc: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/betbtc-logo.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 328,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    emu: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/emucasino-logo-bw.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 388,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sevenB: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/777bay-logo.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 388,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sevenSB: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casinos/777slotsbay-logo.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 388,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
